
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';


import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Brands from '@/modules/shop/Brands.vue';

export default defineComponent({
  name: 'AboutUs',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    Brands
  },
   data() {
    return {
      images: [
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F1_1080x1080.webp?alt=media',
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F2_1080x1080.webp?alt=media',
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F3_1080x1080.webp?alt=media',
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F4_1080x1080.webp?alt=media',
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F5_1080x1080.webp?alt=media',
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F6_1080x1080.webp?alt=media',

      ]
    };
  },
});
