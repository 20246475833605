<template>
  <ion-page>
    <Header></Header>
    <ion-content :fullscreen="true">

      <section id="portada">
        <img src="https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2Fpage-header_1080x1080.webp?alt=media" alt="cover" class="cover" />
      </section>

      <section class="xl-section" id="about">
        <div class="container">
          <h1 class="text-center" style="padding-bottom: 1rem"><strong>Sobre Nosotros</strong></h1>

          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="8">
                <div class="invisible-box">
                  <h5 style="padding-bottom: 1rem"><strong>Novoinox - Inoxidables Chiclana</strong></h5>
                  <p style="margin-bottom: 1rem">Somos empresa emprendedora de reciente creación en Chiclana de la Frontera, Cádiz, que cuenta con un equipo de jóvenes profesionales, con más de 10 años de experiencia en la manufactura de productos de acero inoxidable.</p>
                  <p>Nuestro compromiso con los clientes es garantizar unos altos estándares de calidad en nuestros productos y servicios de atención al cliente.</p>
                  <p></p>
                </div>
              </ion-col>
              <ion-col size="12" size-lg="4">
                <div class="invisible-box">
                  <h5 style="padding-bottom: 1rem"><strong>Piezas a medida</strong></h5>
                  <p>En nuestras instalaciones disponemos de taller propio, especializado en la fabricación única y exclusiva, de todo tipo de productos de acero inoxidable.</p>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </section>

      <section id="gallery">
        <div class="container">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="4" v-for="img in images" :key="img">
                <div class="image-box">
                  <img :src="img" alt="imagen" class="gallery-image"/>
                </div>
              </ion-col>
            </ion-row>

          </ion-grid>
        </div>
      </section>

      <section class="md-section" id="brands">
        <Brands></Brands>
      </section>

      <section class="md-section" id="contact">
        <div class="container">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="8">
                <div class="invisible-box">
                  <h3 style="padding-bottom: 0.5rem"><strong>¿Tienes alguna pregunta?</strong></h3>
                  <h4>Contacta con nosotros</h4>
                </div>
              </ion-col>
              <ion-col size="12" size-lg="4">
                <div class="invisible-box">
                  <ion-button @click="sendMail" shape="round" color="secondary" class="button-contact">Enviar Mensaje</ion-button>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </section>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';


import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Brands from '@/modules/shop/Brands.vue';

export default defineComponent({
  name: 'AboutUs',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    Brands
  },
   data() {
    return {
      images: [
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F1_1080x1080.webp?alt=media',
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F2_1080x1080.webp?alt=media',
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F3_1080x1080.webp?alt=media',
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F4_1080x1080.webp?alt=media',
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F5_1080x1080.webp?alt=media',
        'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/instagram%2Fthumbnails%2F6_1080x1080.webp?alt=media',

      ]
    };
  },
});
</script>

<style scoped>
  .cover{
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
  }

  .button-contact{
    float: right;
    margin-top: 1rem;
  }

  .gallery-image{
    width: 100%;
    height: 360px;
    object-fit: cover;
    object-position: center;
  }

  @media (min-width: 960px) {
    .cover{
      width: 100%;
      height: 450px;
      object-fit: cover;
      object-position: center;
    }
  }
</style>